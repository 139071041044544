import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Store, Select } from '@ngxs/store';
import { OrganizationsStateModel } from '@shared/models';
import { Observable, filter, of, switchMap } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { ApiService } from '../user/api.service';

// from httpClient's get method
type HttpOptions = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | Readonly<string | number | boolean>[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
};

/**
 * use this service instead of the httpClient to make sure
 * some basic headers are already taken care of.
 */
@Injectable({
  providedIn: 'root',
})
export class HrtApiService extends ApiService {
  @Select((state: { organizations: OrganizationsStateModel }) => state.organizations.selected?.organization?.id)
  organizationId$?: Observable<number>;

  constructor(
    @Inject('hrtBaseUrl') protected baseUrl: string,
    @Inject('hrtSubscriptionKey') protected subscriptionKey: string,
    protected msalBroadcast: MsalBroadcastService,
    protected http: HttpClient,
    protected store: Store
  ) {
    super(baseUrl, subscriptionKey, msalBroadcast, http, store);
  }

  /**
   * a wrapper for the httpClient's GET method
   * - sets an organizationId in front of the url
   * @param url the part of the url after the baseUrl
   * @param options object containing extra HTTP Headers or params
   */
  getForOrganization<T>(url: string, options: HttpOptions = {}): Observable<T> {
    if (this.organizationId$) {
      return this.organizationId$?.pipe(
        filter((id: number) => id !== null),
        switchMap<number, Observable<T>>((id: number) => {
          return this.get<T>(`organizations/${id}/${url}`, options);
        })
      );
    } else {
      return of({} as T);
    }
  }

  /**
   * a wrapper for the httpClient's PUT method
   * - sets an organizationId in front of the url
   * @param url the part of the url after the baseUrl
   * @param payload body of the request
   * @param options object containing extra HTTP Headers or params
   */
  putForOrganization<T>(url: string, payload?: unknown, options: HttpOptions = {}): Observable<T> {
    return (
      this.organizationId$?.pipe(filter((id) => id !== null)).pipe(
        switchMap<number, Observable<T>>((id: number) => {
          return this.put<T>(`organizations/${id}/${url}`, payload, options);
        })
      ) || of({} as T)
    );
  }

  /**
   * a wrapper for the httpClient's POST method
   * - sets an organizationId in front of the url
   * @param url the part of the url after the baseUrl
   * @param payload body of the request
   * @param options object containing extra HTTP Headers or params
   */
  postForOrganization<T>(url: string, payload?: unknown, options: HttpOptions = {}): Observable<T> {
    return (
      this.organizationId$?.pipe(filter((id) => id !== null)).pipe(
        switchMap<number, Observable<T>>((id: number) => {
          return this.post<T>(`organizations/${id}/${url}`, payload, options);
        })
      ) || of({} as T)
    );
  }
}
