import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ApiErrorResponse, ErrorHandlerV2Service, SelectOption } from '@gea/digital-ui-lib';
import { catchError, filter, first, map, of, tap } from 'rxjs';

import { FaultsApiService, MachinesApiService } from '@shared/services';
import { FaultAnalysisDto, MachineAndFaultDto, MachineFaultDto, MachineOverviewModel, MachinesStateModel } from '@shared/models';
import {
  ClearMachines,
  FetchMachines,
  SetMachineOverviewData,
  SetSelectedMachines,
  SetSelectedMachinesForFaultAnalysis,
} from './machines.action';
import { ClearFaults, FetchFaultCodes } from '../faults';
import { DateHelper } from '@shared/helpers';
import { FaultComparator } from '@shared/state/faults/fault.comparator';

@State<MachinesStateModel>({
  name: 'machines',
  defaults: {
    machines: [],
    selected: [],
    selectedForFaultAnalysis: [],
    machineAndFaults: [],
    machineOverviewTableData: [],
  },
})
@Injectable()
export class MachinesState {
  constructor(
    readonly machinesApiService: MachinesApiService,
    readonly faultsApi: FaultsApiService,
    readonly errorHandler: ErrorHandlerV2Service,
    readonly store: Store
  ) {}

  @Selector()
  static machines(state: MachinesStateModel): SelectOption[] {
    return state.machines;
  }

  @Selector()
  static selected(state: MachinesStateModel): number[] {
    return state.selected;
  }

  @Selector()
  static selectedForFaultAnalyses(state: MachinesStateModel): number[] {
    return state.selectedForFaultAnalysis;
  }

  @Selector()
  static selectedMachine(state: MachinesStateModel): number {
    return state.selected[0];
  }

  @Selector()
  static machineAndFaults(state: MachinesStateModel): MachineAndFaultDto[] {
    return state.machineAndFaults;
  }

  @Selector()
  static machineOverviewData(state: MachinesStateModel): MachineOverviewModel[] {
    return state.machineOverviewTableData;
  }

  @Action(FetchMachines)
  fetchMachines({ patchState }: StateContext<MachinesStateModel>) {
    return this.machinesApiService.fetchMachines().pipe(
      filter((m) => m.length > 0),
      catchError((error: ApiErrorResponse) => {
        this.errorHandler.handleError(error);
        return of([]);
      }),
      first(),
      map((machines: MachineAndFaultDto[]) => {
        patchState({
          machineAndFaults: machines,
        });
        return machines.map((machine) => ({
          name: machine.name,
          value: machine.id,
        }));
      }),
      tap((machines: SelectOption<number>[]) => {
        patchState({
          machines,
          selected: machines.map((machine) => machine.value),
          selectedForFaultAnalysis: machines.map((machine) => machine.value),
        });
        this.store.dispatch(new ClearFaults());
        this.store.dispatch(new SetSelectedMachinesForFaultAnalysis(machines.map((machine) => machine.value)));
      })
    );
  }

  @Action(SetSelectedMachines)
  setSelectedMachines({ patchState }: StateContext<MachinesStateModel>, { machines }: SetSelectedMachines) {
    patchState({
      selected: machines,
    });
  }

  @Action(SetSelectedMachinesForFaultAnalysis)
  setSelectedMachinesForFaultAnalysis(
    { patchState, dispatch }: StateContext<MachinesStateModel>,
    { machines }: SetSelectedMachines
  ) {
    patchState({
      selectedForFaultAnalysis: machines,
    });

    if (machines.length) {
      dispatch(new FetchFaultCodes(machines));
    }
  }

  @Action(ClearMachines)
  clearMachines({ patchState }: StateContext<MachinesStateModel>) {
    return of(
      patchState({
        machines: [],
        machineAndFaults: [],
        machineOverviewTableData: [],
        selected: [],
      })
    );
  }

  @Action(SetMachineOverviewData)
  setMachineOverviewData({ patchState, getState }: StateContext<MachinesStateModel>) {
    let allShutdowns: MachineFaultDto[] = [];
    let allWarnings: MachineFaultDto[] = [];
    const machines = getState().machineAndFaults;
    this.faultsApi
      .fetchFaultCodes(
        machines.map((a) => a.id),
        DateHelper.daysBack(28),
        ['0']
      )
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error) {
            this.errorHandler.handleError(error);
          }
          const faultAnalysis: FaultAnalysisDto = {
            machines: [],
            shutdowns: [],
            warnings: [],
            faultHistory: [],
          };

          return of({ faultCodes: [], faultCodeGroups: [], faultAnalysis });
        }),
        first()
      )
      .subscribe(({ faultAnalysis }) => {
        let { shutdowns, warnings } = faultAnalysis;

        shutdowns = [...shutdowns].sort(FaultComparator.by('totalPending', 'desc'));
        warnings = [...warnings].sort(FaultComparator.by('totalPending', 'desc'));
        allWarnings = warnings;
        allShutdowns = shutdowns;

        const machineOverViewList: MachineOverviewModel[] = [];

        for (const machine of machines) {
          const machineOverviewModel: MachineOverviewModel = {
            machine: {
              id: machine.id,
              name: machine.name,
              nameInCRM: machine.nameInCRM,
              equipmentType: machine.equipmentType,
              omniId: machine.omniId,
            },
            warnings: allWarnings.filter((w) => w.machineId === machine.id).length ?? 0,
            shutdowns: allShutdowns.filter((s) => s.machineId === machine.id).length ?? 0,
          };
          machineOverViewList.push(machineOverviewModel);
        }

        patchState({
          machineOverviewTableData: machineOverViewList,
        });
      });
  }
}
