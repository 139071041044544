<div class="health-check-dialog-container" [class]="computedClass()">
  <div class="dialog-header" [class]="computedClass()">
    <gea-hrt-heading class="title" type="sub">
      <h2>{{ data.name | toTitle }}</h2>
    </gea-hrt-heading>
    <div [ngSwitch]="data.status">
      <geaui-icon-v2 *ngSwitchCase="0" file="check_white" />
      <geaui-icon-v2 *ngSwitchCase="1" file="warning_triangle" />
      <geaui-icon-v2 *ngSwitchCase="2" file="warning_round" />
      <geaui-icon-v2 *ngSwitchDefault file="check_white" />
    </div>
  </div>
  <div>
    <ul>
      <li class="flex">
        <div class="key">{{ 'SYSTEM_HEALTH.PARAMETER_NAMES.StartTime' | translate }}</div>
        <div class="value"><gea-hrt-update-time [date]="data.startTimestamp" /></div>
      </li>
      <li class="flex">
        <div class="key">{{ 'SYSTEM_HEALTH.PARAMETER_NAMES.EndTime' | translate }}</div>
        <div class="value"><gea-hrt-update-time [date]="data.endTimestamp" /></div>
      </li>
      <li class="flex" *ngFor="let meta of metadata">
        <div class="key">{{ meta[0] | toTitle }}</div>
        <div class="value">{{ meta[1] | number: '.1-2' }}</div>
      </li>
    </ul>
    <div class="hints">Hint: {{ data.hints }}</div>
    <gea-hrt-health-diagram [data]="data.timelineEntries"></gea-hrt-health-diagram>
  </div>
</div>
