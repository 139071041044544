<span class="co-action-cell">
  <div class="selected-customer" *ngIf="(organizationId$ | async) === organization?.id; else notSelected">
    <span class="selected-customer-icon">
      <geaui-icon-v2 file="check_green" />
    </span>
    <span class="selected-customer-label">{{ 'CUSTOMER_OVERVIEW.CUSTOMER_SELECTED' | translate }}</span>
  </div>
  <ng-template #notSelected>
    <div class="select-customer-button">
      <geaui-button-v2 buttonType="primary" buttonSize="md" [disabled]="isDisabled" (click)="selectOrganization(organization)">
        {{ 'CUSTOMER_OVERVIEW.SELECT_CUSTOMER' | translate }}
      </geaui-button-v2>
    </div>
  </ng-template>
</span>
