import { Injectable } from '@angular/core';

import { ApiErrorResponse } from '@gea/digital-ui-lib';
import { Observable, throwError } from 'rxjs';

import { HrtApiService } from '../hrt-api/hrt-api.service';
import { MeasurementSerieDto } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class RelatedOperatingDataApiService {
  url = 'machines/{machine}/relatedOperatingDatas';

  constructor(readonly api: HrtApiService) {}

  fetchRelatedOperatingData(
    machine: number,
    dateHours?: string,
    temperatureUnit?: string,
    pressureUnit?: string
  ): Observable<MeasurementSerieDto[]> {
    if (!machine) {
      return throwError(
        () =>
          ({
            message: 'TROUBLESHOOTING.CLIENT_ERROR.NO_FAULT_CHOSEN',
          }) as ApiErrorResponse
      );
    }
    const url = this.url.replace('{machine}', machine.toString());
    const queryParams = {
      dateRange: dateHours ?? '',
      temperatureUnit: temperatureUnit ?? '',
      pressureUnit: pressureUnit ?? '',
    };
    return this.api.getForOrganization<MeasurementSerieDto[]>(url, { params: queryParams });
  }
}
