import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import * as echarts from 'echarts';
import { EChartsOption, GridComponentOption } from 'echarts';

interface BarChartDataSet<YValue> {
  name: string;
  stackName?: string; // for "stacked" bar charts only
  color?: string; // if "undefined" default color set will be applied
  pattern?: {
    type: BarChartPatternType;
    color?: string;
  };
  data: YValue[];
}

export type BarChartLegendPositionY = 'top' | 'bottom';
export type BarChartLegendPositionX = 'left' | 'right' | 'center';
export type BarChartPatternType = 'diagonal-line';
export type ChartPadding = Pick<GridComponentOption, 'left' | 'right' | 'top' | 'bottom'>;

export interface BarChartData<XValue, YValue> {
  showTooltip?: boolean;
  showLegend?: boolean;
  legendPosition?: [BarChartLegendPositionY, BarChartLegendPositionX];
  padding?: { [Key in keyof ChartPadding]?: number | string }; // pixels or % (echarts dynamic values will be used when not provided here)
  xValues: XValue[];
  yValues: BarChartDataSet<YValue> | BarChartDataSet<YValue>[];
  legend?: Record<string, unknown>;
}

function resolvePattern(pattern: BarChartPatternType) {
  if (pattern === 'diagonal-line') {
    return {
      dashArrayX: [1, 0],
      dashArrayY: [1, 3],
      rotation: -Math.PI / 4,
    };
  } else {
    throw new Error('Unknown pattern');
  }
}

function formatTooltip(params: { seriesIndex: number; marker: string; value: string }) {
  const solved = [1, 3].indexOf(params.seriesIndex) !== -1;
  const shutdown = [0, 1].indexOf(params.seriesIndex) !== -1;
  const resolutionClassName = solved ? 'solved' : 'pending';
  const faultTypeClassName = shutdown ? 'shutdown' : 'warning';

  const marker = params.marker
    .replace('<span ', `<span class="fault-history-marker-${faultTypeClassName} fault-history-marker-${resolutionClassName}" `)
    .replace('<br/>', '');
  return marker + `<div class="tooltip">${params.value}</div>`;
}

@Component({
  selector: 'gea-hrt-bar-chart[data]',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarChartComponent<XValue = string, YValue = number> implements OnChanges, AfterViewInit {
  @Input()
  title?: string;

  @Input()
  width?: string;

  @Input()
  height?: string;

  @Input({ required: true })
  data!: BarChartData<XValue, YValue> | null;

  @ViewChild('chart') chartRef!: ElementRef<HTMLDivElement>;
  public chart!: echarts.EChartsType;

  options!: EChartsOption;

  ngOnChanges(changes: SimpleChanges) {
    this.setChartOptions(changes.data.currentValue as BarChartData<XValue, YValue>);
  }

  setChartOptions(data: BarChartData<XValue, YValue>) {
    if (data) {
      this.options = this.createChartOptions(data);
    }
  }
  ngAfterViewInit() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    this.chart = echarts.init(this.chartRef.nativeElement);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    this.chart.setOption(this.options);
  }

  private createChartOptions(data: BarChartData<XValue, YValue>) {
    if (!Array.isArray(data.yValues)) {
      data.yValues = [data.yValues];
    }

    const options: Record<string, unknown> = {
      title: {
        text: this.title,
        show: this.title && this.title !== '',
      },
      legend: {
        show: this.data?.showLegend,
        left: data.legendPosition ? data.legendPosition[1] : 'left',
        top: data.legendPosition ? data.legendPosition[0] : 'top',
        icon: 'circle',
        ...data.legend,
      },
      tooltip: {
        show: data.showTooltip,
        className: 'echarts-tooltip echarts-tooltip-fault-history',
        extraCssText: 'display: flex;',
        formatter: formatTooltip,
      },
      xAxis: {
        type: 'category',
        data: data.xValues,
      },
      yAxis: {
        type: 'value',
        minInterval: 1,
        scale: false,
      },
      series: data?.yValues?.map((row) => ({
        type: 'bar',
        name: row.name,
        color: row.color,
        stack: row.stackName,
        data: row.data,
        itemStyle: row.pattern
          ? {
              decal: {
                ...resolvePattern(row.pattern.type),
                color: row.pattern.color,
              },
            }
          : undefined,
      })),
    };

    if (data.padding) {
      options.grid = this.defineDistance(data.padding);
    }

    return options;
  }

  @HostListener('window:resize')
  onResize() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    this.chart.resize();
  }

  private defineDistance(padding: ChartPadding) {
    const distanceLeft = 25;
    const left = padding.left ? padding.left : distanceLeft;
    const right = padding.right ? padding.right : 0;
    return {
      ...padding,
      left,
      right,
    };
  }
}
