<div class="image-preview-container">
  <div class="button" (click)="onAddImage()" *ngIf="!images.length">
    <geaui-icon-v2 file="image" class="add-images-button" />
    {{ 'SHARED.IMAGES_PREVIEW.ADD_IMAGES' | translate }}
  </div>
  <input
    #fileInput
    hidden
    type="file"
    id="fileInput"
    multiple
    value="Select"
    accept="image/*"
    title="Add image"
    (change)="onFileSelected($event)" />

  <div class="flex">
    <div *ngFor="let image of images; let i = index; trackBy: trackByIndex" class="image-preview">
      <img [src]="image" alt="Preview" class="preview-image" />
      <geaui-icon-v2
        class="delete-button"
        file="trash-can"
        (click)="onDeleteImage(i)"
        [title]="'SHARED.IMAGES_PREVIEW.DELETE_IMAGE' | translate" />
    </div>
    <div *ngIf="images.length" class="add-button" (click)="onAddImage()">
      <b>+</b>
    </div>
  </div>
  <div class="warning-5mb" *ngIf="show5mbWarning">
    <geaui-icon-v2 file="error-icon" color="cancel-red"></geaui-icon-v2>
    {{ 'SHARED.IMAGES_PREVIEW.5MB_WARNING' | translate }}
  </div>
</div>
