import { FaultRecommendationsApiService } from '@app/shared/services';
import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiErrorResponse, ErrorHandlerV2Service } from '@gea/digital-ui-lib';
import { EMPTY, catchError, first, of, tap } from 'rxjs';

import {
  CreateRecommendationFromResolvedFault,
  FetchAnnunciationsMetadata,
  FetchFaultCatalog,
  FetchResolvedFaults,
} from './fault-recommendations.action';
import {
  FaultCatalog,
  AnnunciationsMetaData,
  FaultRecommendationsStateModel,
  ResolvedFault,
  RecommendationDto,
} from '@shared/models';
import { FetchRecommendations } from '../recommendations';

export const defaultFaultCatalog: FaultCatalog = [];
export const defaultResolvedFaults: ResolvedFault[] = [];

export const defaultAnnunciationsMetadata: AnnunciationsMetaData = {
  shutdowns: 0,
  totalFaults: 0,
  warnings: 0,
  withoutRecommendations: 0,
};

@State<FaultRecommendationsStateModel>({
  name: 'faultRecommendations',
  defaults: {
    faultCatalog: defaultFaultCatalog,
    annunciationsMetadata: defaultAnnunciationsMetadata,
    resolvedFaults: defaultResolvedFaults,
  },
})
@Injectable()
export class FaultRecommendationsState {
  constructor(
    public readonly faultRecommendationsApiService: FaultRecommendationsApiService,
    public readonly errorHandler: ErrorHandlerV2Service
  ) {}

  @Selector()
  static faultCatalog(state: FaultRecommendationsStateModel): FaultCatalog {
    return state.faultCatalog;
  }

  @Selector()
  static annunciationsMetadata(state: FaultRecommendationsStateModel): AnnunciationsMetaData {
    return state.annunciationsMetadata;
  }

  @Selector()
  static resolvedFaults(state: FaultRecommendationsStateModel): ResolvedFault[] {
    return state.resolvedFaults;
  }

  @Action(FetchFaultCatalog)
  fetchFaultCatalog(
    { patchState }: StateContext<FaultRecommendationsStateModel>,
    { type, hasRecommendations }: FetchFaultCatalog
  ) {
    this.faultRecommendationsApiService
      .fetchFaultCatalog({ type, hasRecommendations })
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error) {
            this.errorHandler.handleError(error);
          }

          return of(defaultFaultCatalog);
        }),
        first()
      )
      .subscribe((faultCatalog: FaultCatalog) => {
        patchState({
          faultCatalog,
        });
      });
  }

  @Action(FetchAnnunciationsMetadata)
  fetchAnnunciationsMetadata({ patchState }: StateContext<FaultRecommendationsStateModel>) {
    this.faultRecommendationsApiService
      .fetchAnnunciationsMetadata()
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error) {
            this.errorHandler.handleError(error);
          }

          return of(defaultAnnunciationsMetadata);
        }),
        first()
      )
      .subscribe((annunciationsMetadata: AnnunciationsMetaData) => {
        patchState({
          annunciationsMetadata,
        });
      });
  }

  @Action(FetchResolvedFaults)
  fetchResolvedFaults({ patchState }: StateContext<FaultRecommendationsStateModel>) {
    this.faultRecommendationsApiService
      .fetchResolvedFaults()
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error) {
            this.errorHandler.handleError(error);
          }

          return of(defaultResolvedFaults);
        }),
        first()
      )
      .subscribe((resolvedFaults: ResolvedFault[]) => {
        patchState({
          resolvedFaults,
        });
      });
  }

  @Action(CreateRecommendationFromResolvedFault)
  createRecommendationFromResolvedFault(
    { dispatch }: StateContext<FaultRecommendationsStateModel>,
    { resolvedFault, reviewStatus }: CreateRecommendationFromResolvedFault
  ) {
    return this.faultRecommendationsApiService.createRecommendationFromResolvedFault(resolvedFault, reviewStatus).pipe(
      catchError((error: ApiErrorResponse) => {
        this.errorHandler.handleError(error);
        return EMPTY;
      }),
      tap((newRecommendation: RecommendationDto) => {
        if (newRecommendation) {
          dispatch(new FetchRecommendations(resolvedFault.machineId, resolvedFault.faultCode));
        }
      })
    );
  }
}
