import { GetHealthCheck } from './system-health.action';
import { catchError, of, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiErrorResponse, ErrorHandlerV2Service } from '@gea/digital-ui-lib';

import { SystemHealthApiService } from '@shared/services';
import { MachineStatus, SystemHealthMachine, SystemHealthMachineComponent, SystemHealthModel } from '@shared/models';

@State<SystemHealthModel>({
  name: 'SystemHealth',
})
@Injectable()
export class SystemHealthState {
  constructor(
    readonly apiService: SystemHealthApiService,
    readonly errorHandler: ErrorHandlerV2Service
  ) {}

  @Selector()
  static machine(state: SystemHealthModel): SystemHealthMachine {
    return state.machine;
  }

  @Selector()
  static lastHealthCheckTime(state: SystemHealthModel): string | undefined {
    return state?.lastHealthCheckTime;
  }

  @Selector()
  static components(state: SystemHealthModel): SystemHealthMachineComponent[] {
    return state.machine.components;
  }

  @Selector()
  static machineStatus(state: SystemHealthModel): MachineStatus {
    return state.machine.status;
  }

  @Action(GetHealthCheck)
  getHealthCheck({ patchState }: StateContext<SystemHealthModel>, { machines, dateRange }: GetHealthCheck) {
    return this.apiService.getHealthCheck(machines, dateRange).pipe(
      catchError((error: ApiErrorResponse) => {
        if (error?.headers) {
          this.errorHandler.handleError(error);
        }
        return of({});
      }),
      tap((systemHealth) => {
        patchState(systemHealth);
      })
    );
  }
}
