<section>
  <div>
    <gea-hrt-fault-card [cardType]="'shutdown'" [displayNumber]="rowData?.countShutdowns ?? 0" />
  </div>
  <div>
    <gea-hrt-fault-card [cardType]="'warning'" [displayNumber]="rowData?.countWarnings ?? 0" />
  </div>
  <div>
    <gea-hrt-co-action-cell [organization]="rowData" />
  </div>
</section>
