import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HrtApiService } from '../hrt-api/hrt-api.service';
import {
  FaultCatalog,
  FaultCatalogType,
  AnnunciationsMetaData,
  ResolvedFault,
  RecommendationDto,
  RecommendationReviewStatus,
  RecommendationStatus,
} from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class FaultRecommendationsApiService {
  baseUrl = 'settings/catalog';

  constructor(readonly api: HrtApiService) {}

  fetchFaultCatalog({
    type,
    hasRecommendations,
  }: {
    type?: FaultCatalogType;
    hasRecommendations?: boolean;
  }): Observable<FaultCatalog> {
    let queryParams = type ? `?type=${type}` : '';

    if (hasRecommendations) {
      queryParams += `${type ? '&' : '?'}hasRecommendations=${hasRecommendations}`;
    }

    return this.api.get(`${this.baseUrl}/faults${queryParams}`);
  }

  fetchAnnunciationsMetadata(): Observable<AnnunciationsMetaData> {
    return this.api.get(`${this.baseUrl}/metadata`);
  }

  fetchResolvedFaults(): Observable<ResolvedFault[]> {
    return this.api.get(`${this.baseUrl}/resolved-faults`);
  }

  createRecommendationFromResolvedFault(
    { additionalNote: actionName, images, faultCode, id }: ResolvedFault,
    reviewStatus: RecommendationReviewStatus
  ): Observable<RecommendationDto> {
    return this.api.post(`${this.baseUrl}/create-recommendation`, {
      id,
      actionName,
      faultCode,
      images,
      reviewStatus,
      status: RecommendationStatus.PENDING,
    });
  }
}
