import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LayoutService, SelectOption } from '@gea/digital-ui-lib';
import { FiltersBaseComponentComponent } from '@app/shared/components';

@Component({
  selector: 'gea-hrt-health-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class HealthFiltersComponent extends FiltersBaseComponentComponent {
  @Output() setSelectedMachine = new EventEmitter<SelectOption<number>>();

  @Input({ required: true }) machineOptions: SelectOption<number>[] = [];
  @Input({ required: true }) machine!: number;

  formGroup = new FormGroup({
    machine: new FormControl<number[]>([]),
  });

  constructor(
    public translate: TranslateService,
    public layout: LayoutService
  ) {
    super(layout, translate);

    this.translateOptions();
  }

  selectMachine(event: { value: SelectOption<number> } | SelectOption<number>) {
    if (this.isSelectOption(event)) {
      this.setSelectedMachine.emit(event);
    } else {
      this.setSelectedMachine.emit(event.value);
    }
  }
}
