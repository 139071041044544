<div class="info-box">
  <div class="mdc-layout-grid__inner">
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
      <mat-card [ngClass]="{ 'with-border': withBorder }">
        <mat-card-content>
          <div class="flex">
            <gea-hrt-heading>{{ boxTitle | translate }}</gea-hrt-heading>
            <geaui-icon-v2
              pTooltip="{{ tooltip | translate }}"
              [color]="'grey'"
              tooltipPosition="left"
              [file]="'c-info'"
              [fileSize]="16" />
          </div>
          <div><ng-content></ng-content></div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
