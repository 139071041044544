import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as echarts from 'echarts';
import { EChartsOption } from 'echarts';
import { formatDate } from '@angular/common';
import { ChartData, ChartSerie } from '@tab-container/troubleshooting';
import { TimelineEntry } from '@shared/models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SelectOption, SelectV2Module } from '@gea/digital-ui-lib';

@Component({
  selector: 'gea-hrt-health-diagram',
  standalone: true,
  imports: [SelectV2Module, TranslateModule],
  templateUrl: './health-diagram.component.html',
  styleUrl: './health-diagram.component.scss',
})
export class HealthDiagramComponent implements OnChanges, AfterViewInit {
  @Input({ required: true })
  data!: TimelineEntry[];
  @ViewChild('chart')
  chartRef!: ElementRef<HTMLDivElement>;
  public chart!: echarts.EChartsType;

  options!: EChartsOption;

  selectedDays = 7;
  selectOptions = [
    { value: 7, name: this.translation.instant('SYSTEM_HEALTH.DIAGRAM.7DAYS') },
    { value: 365, name: this.translation.instant('SYSTEM_HEALTH.DIAGRAM.1YEAR') },
  ];
  constructor(readonly translation: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    const y = this.generateSeries(changes.data.currentValue);
    this.setChartOptions({ series: y });
  }

  isSelectOption(obj: unknown): obj is SelectOption<number> {
    return (obj as SelectOption<number>).name !== undefined && (obj as SelectOption<number>).value !== undefined;
  }

  rangeChanged(event: { value: SelectOption } | SelectOption<number>) {
    this.selectedDays = this.isSelectOption(event) ? event.value : event.value.value;

    const y = this.generateSeries(this.data);
    this.setChartOptions({ series: y });
  }

  private generateSeries(data: TimelineEntry[]): ChartSerie[] {
    let leftOffset = 0;
    let rightOffset = 0;
    const c: { color: string; yAxisPosition: 'left' | 'right' }[] = [
      { color: 'black', yAxisPosition: 'left' },
      { color: 'red', yAxisPosition: 'right' },
      { color: 'green', yAxisPosition: 'left' },
      { color: 'blue', yAxisPosition: 'right' },
      { color: 'yellow', yAxisPosition: 'right' },
    ];

    const result: ChartSerie[] = [];
    let count = 0;
    for (const t of data) {
      const yAxisOffset = c[count].yAxisPosition === 'left' ? leftOffset : rightOffset;

      if (c[count].yAxisPosition === 'left') {
        leftOffset += 50;
      } else {
        rightOffset += 50;
      }
      const now = new Date();
      now.setDate(now.getDate() - this.selectedDays);
      const filteredEntries = t.entries.filter((entry) => new Date(entry.key.toString()) > now);
      const cs: ChartSerie = {
        name: this.translation.instant('SYSTEM_HEALTH.TIMELINE.TYPE.' + t.name.toLowerCase()) || t.name,
        color: c[count].color,
        data: filteredEntries.map((a) => [a.key, a.value]),
        legendLabel: this.translation.instant('SYSTEM_HEALTH.TIMELINE.TYPE.' + t.name.toLowerCase()) || t.name,
        yAxisName: count.toString(),
        yAxisPosition: c[count].yAxisPosition,
        yAxisOffset,
        xValues: filteredEntries.map((a) => a.key.toString()),
      };
      result.push(cs);
      count++;
    }

    return result;
  }
  @HostListener('window:resize')
  onResize() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    this.chart.resize();
  }
  setChartOptions(data: ChartData) {
    this.options = this.createChartOptions(data);

    // initially there is no echart instance available
    if (this.chartRef) {
      this.chart.setOption(this.options, { notMerge: true });
    }
  }
  ngAfterViewInit() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    this.chart = echarts.init(this.chartRef.nativeElement);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    this.chart.setOption(this.options);
  }
  createChartOptions(data: ChartData): EChartsOption {
    let leftYAxisDims = 0;
    let rightYAxisDims = 0;
    data.series.forEach(({ yAxisPosition }) => (yAxisPosition === 'left' ? leftYAxisDims++ : rightYAxisDims++));

    return {
      grid: {
        // dynamically calculate chart paddings (25px is the minimal padding -> it won't cut the values of last items)
        left: leftYAxisDims > 0 ? leftYAxisDims * 50 : 25,
        right: rightYAxisDims > 0 ? rightYAxisDims * 50 : 25,
        bottom: 150,
      },
      useUTC: true,
      tooltip: {
        className: 'echarts-fault-history-tooltip',
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            formatter: (value) => {
              if (typeof value.value === 'number') {
                if (value.value > 10000) return formatDate(value.value, 'YYYY-MM-dd HH:mm:SS', 'en-US');

                return value.value.toFixed(2);
              }
              return value.value.toString();
            },
          },
        },
      },
      legend: {
        data: data.series.map(({ legendLabel }) => legendLabel),
        icon: 'circle',
        left: 'right',
        top: 'bottom',
      },
      dataZoom: [
        {
          show: true,
          bottom: 50,
        },
      ],
      xAxis: {
        type: 'time',
        axisLabel: {
          formatter: (value) => {
            return formatDate(value, 'YYYY-MM-dd HH:mm', 'en-US');
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
            //color: CHART_GRAY_COLOR,
          },
        },
        axisLine: {
          show: false,
          lineStyle: {
            //color: CHART_GRAY_COLOR,
          },
        },
      },
      yAxis: data.series.map((serie) => {
        return {
          type: 'value',
          name: serie.yAxisName,
          position: serie.yAxisPosition,
          offset: serie.yAxisOffset,
          splitLine: {
            show: true,
            interval: 'auto',
            lineStyle: {
              type: 'dashed',
              //color: CHART_GRAY_COLOR,
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: serie.color,
            },
          },
        };
      }),
      series: data.series.map((serie, i) => ({
        name: serie.name,
        type: 'line',
        showSymbol: false,
        color: serie.color,
        yAxisIndex: i,
        data: serie.data,
      })),
    };
  }
}
