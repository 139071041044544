<div class="system-health-container">
  <div class="section filters">
    <ng-container *ngIf="machineOptions$ | async as machineOptions">
      <ng-container *ngIf="machineOptions.length > 0">
        <gea-hrt-health-filters
          [machineOptions]="machineOptions"
          [machine]="machine ?? machineOptions[0].value"
          (setSelectedMachine)="setMachine($event.value)" />
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="(components$ | async)?.length">
    <div class="section" *ngIf="lastHealthCheckTime$ | async as lastHealthCheckTime">
      <p class="last-reloaded">
        {{ 'SYSTEM_HEALTH.DATA_EXTRACTED_ON' | translate }}:
        <gea-hrt-update-time [date]="lastHealthCheckTime" />
      </p>
    </div>
  </ng-container>
  <div class="section">
    <ng-container *ngIf="selectedMachine$ | async as selectedMachine">
      <ng-container *ngIf="components$ | async as components">
        <ng-container *ngIf="organizationId$ | async as organizationId">
          <p-accordion [activeIndex]="0">
            <ng-container *ngFor="let comp of components; trackBy: trackByComponentId">
              <gea-hrt-system-health-machine-component
                [component]="comp"
                [machineId]="selectedMachine"
                [organizationId]="organizationId" />
            </ng-container>
          </p-accordion>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
