<div class="fault-analysis-filters-container" [ngClass]="layout.screenClasses | async">
  <div class="dropdown">
    <geaui-select-v2
      [id]="'machine'"
      [label]="'FAULT_ANALYSIS.MACHINE' | translate"
      [group]="formGroup"
      [options]="machineOptions"
      [selected]="{ value: machine }"
      (selectChanged)="selectMachine($event)" />
  </div>
</div>
