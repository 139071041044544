<div>
  <geaui-select-v2
    style="width: 160px; display: block"
    [id]="'select'"
    label="{{ 'SYSTEM_HEALTH.DIAGRAM.DATERANGE' | translate }}"
    [options]="selectOptions"
    [selected]="selectOptions[0]"
    (selectChanged)="rangeChanged($event)"></geaui-select-v2>
  <div class="chart-container" id="chart-container" #chart style="height: 600px; width: 100%"></div>
</div>
